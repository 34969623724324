<template>
  <li class="artist-item" @click="() => gotoProfile(row)">
    <PuSkeleton :class="{'skeleton': imageLoading}" :loading="imageLoading" width="17rem" height="17rem">
      <div
      class="artist-img ui-bg-img"
      :style="{
          backgroundImage: `url(${$lib.cdnUrl(row.teamImgUrl || row.imgUrl1)})`
      }"
      >
        <div class="artist-badge">
            <div class="badge-read" v-if="isRead">읽음</div>
            <!-- <div class="artistCheck" v-if="row.checkDate > 0">신원인증</div> -->
            <div class="artistHot" v-if="row.popularDate > 0">추천</div>
            <div class="artistCheck" v-if="!!row.eventMemo">이벤트</div>
        </div>
      </div>
    </PuSkeleton>
    <div class="artist-info">
      <template v-if="isEarlybirdEventArtist">
        <div class="artistEvent">
          {{Math.round(row.salePer || 0)}}%할인 - {{earlybirdEventTimer}} 남음
        </div>
      </template>

      <div class="userNickName">{{row.teamName}}</div>
      <!-- <div class="genre-location">
        {{ $lib.t_genre(row.categoryType != 'supplies' ? row.genre : row.categoryName) }}<span>&middot;</span>{{$lib.getLocationText(row.location)}}
      </div> -->
      <ul class="tag" v-if="row.hashTag">
        <template v-for="(tag, tagKey) in row.hashTag.split(/,\s?/)">
          <li
            :key="tagKey"
            v-if="tag"
            @click.capture.stop="searchArtistByTag(tag)">
            {{tag}}
          </li>
        </template>
      </ul>

      <AEvaluation :favCount="row.favCount" :pointCount="row.pointCount" :reviewCount="row.reviewCount"></AEvaluation>

      <template v-if="isEarlybirdEventArtist">
        <div class="artist-info-price">
          <span
            :style="{
              textDecoration: 'line-through',
              fontSize: '18px',
              color: '#b6b6b6',
              marginRight: '6px',
            }"
          >
            {{$lib.addComma(row.proPrice)}}원
          </span>
          <span>{{$lib.addComma($lib.floor(Number(row.proPrice) - $lib.floor(Number(row.proPrice) * Number(row.salePer) / 100), 2))}}원</span>
        </div>
      </template>
      <template v-else-if="isAppliedArtist">
        <div class="artist-info-price">
          {{$lib.addComma(row.proPrice)}}원
        </div>
      </template>
      <template v-else-if="isDiscountEventCustomArtist">
        <div class="artist-info-price">
          <span class="color-lavender">{{Math.round(row.salePer || 0)}}%</span> {{$lib.addComma($lib.floor(Number(row.cusPriceStart) - $lib.floor(Number(row.cusPriceStart) * Number(row.salePer) / 100), 2))}}원
        </div>
      </template>
      <!-- v1.0.0 변동가 case -->
      <template v-else-if="isFluctuating">
        <div class="artist-info-price">
          {{$lib.addComma(row.cusPriceStart)}}원 ~ {{$lib.addComma(row.cusPriceEnd)}}원
        </div>
      </template>
      <!-- v1.0.0 변동가 case -->
      <template v-else-if="isFluctuatingStart">
        <div class="artist-info-price">
          {{$lib.addComma(row.cusPriceStart)}}원 ~ 
        </div>
      </template>
      <!-- // v1.0.0 변동가 case -->
      <template v-else-if="isCustomArtist && row.cusPriceStart > 0">
        <div class="artist-info-price">
          {{$lib.addComma(row.cusPriceStart)}}원
        </div>
      </template>
    </div>
  </li>
</template>

<script>
import StarRating from 'vue-star-rating'
import AEvaluation from './AEvaluation.vue'

import {
  isAppliedArtist,
  isEarlybirdEventArtist,
  calcEarlybirdEventTimer,
  isCustomArtist,
  isDiscountEventCustomArtist,
  isFluctuating,
} from '@/lib/artist.js'

export default {
  components: {
    StarRating,
    AEvaluation
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    noSearchArtistByTag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      offerData: {},
      earlybirdEventTimer: '24:00:00',
      imageLoading:false,
    }
  },
  computed: {
    userData: {
      get() {
        return this.$store.state.user.userData
      }
    },
    offerUid() {
      return this.$route.params?.offerUid || this.row?.offerUid
    },
    isRead() {
      if (this.userData.category === 'client') {
        return localStorage.getItem(`read:estimate:${this.row.offerUid}:${this.row.artistidx}`) > 0
      }

      return false
    },
    isAppliedArtist() {
      return isAppliedArtist(this.row)
    },
    isEarlybirdEventArtist() {
      return this.isAppliedArtist && isEarlybirdEventArtist(this.row, this.offerData)
    },
    isCustomArtist() {
      return isCustomArtist(this.row, this.userData)
    },
    isDiscountEventCustomArtist() {
      return isDiscountEventCustomArtist(this.row)
    },
    isFluctuating() {
      return isFluctuating(this.row) && this.row.cusPriceEnd > 0;
    },
    isFluctuatingStart() {
      return isFluctuating(this.row);
    },
  },
  created() {
    if (this.isAppliedArtist) {
      this.getOffer()
    }

    this.loadBackgroundImages();
  },
  methods: {
    gotoProfile(row) {
      if (this.$route.name === 'home') {
        this.$router.push(`/artist/my-profile`)
      } else if (!this.$route.params.offerUid) {
        this.$router.push({
          path: `/artist/profile/${row.artistidx}`,
          query: {
            main_menu_artist_uid: row.mainMenuArtistUid,
          },
        })
      } else {
        sessionStorage.setItem('offerData', JSON.stringify(row))
        this.$router.push(`/client/my-offer/${row.offerUid}/${row.artistidx}`)
      }
    },
    getOffer() {
      if (!this.offerUid) {
        return
      }

      const req = {
        method: 'get',
        url: `/client/offer/${this.offerUid}`,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let offerData = data.response.offerData || {}

            if (!offerData.offerUid) {
              this.$router.back()
              return
            }

            this.offerData = offerData
            this.startTimer()
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    startTimer() {
      calcEarlybirdEventTimer(this.row, this.offerData, (time) => {
        this.earlybirdEventTimer = time
      })
    },
    searchArtistByTag(tag) {
      //
      // if (!tag) {
      //   return
      // }

      // if (this.userData.category !== 'client') {
      //   return
      // }

      // if (this.noSearchArtistByTag) {
      //   return
      // }

      // this.$router.push({
      //   path: '/artist/list',
      //   query: {
      //     hashTag: tag,
      //   },
      // })
    },
    
    loadBackgroundImages() {
      this.imageLoading = true;

      const img = new Image();

      img.src = this.$lib.cdnUrl(this.row.teamImgUrl || this.row.imgUrl1);

      img.onload = () => {
        this.imageLoading = false;
      };

      img.onerror = (e) => {
        this.imageLoading = false;
      }; 
    }
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/css/constant.scss';

  .artist-item {
    overflow: hidden;
    margin: 2.8rem auto;
    display: flex;
    align-items: center;

    .artist-badge {
      overflow: hidden;

      > div {
        display: inline-block;
        width: auto;
        padding: 0 0.6rem;
        margin-right: 0.6rem;
        margin-bottom: 1.6rem;
        line-height: 2.5rem;
        height: 2.2rem;
        border-radius: 0.5rem;
        color: #fff;
        font-size: 1.4rem;
        text-align: center;
        &.artistCheck {
          background-color: #30c39e;
        }
        &.artistHot {
          background-color: #a059b6;
        }
        &.badge-read {
          background-color: #ffcb39;
        }
        &.badge-event-outline {
          color: $color-deepLavender;
          background-color: transparent;
          border: 1px solid $color-deepLavender;
        }
      }
    }

    .skeleton {
      margin-right: 2.8rem;
    }

    .artist-img {
      position: relative;
      float: left;
      margin-right: 2.8rem;
      min-width: 17rem;
      width: 17rem;
      height: 17rem;
      border-radius: 1rem;
      background-color: #d8d8d8;

      .artist-badge {
        position: absolute;
        left: 8px;
        bottom: -10px;
      }
    }

    .artist-info {
      float: left;
      max-width: 28.2rem;

      .userNickName {
        margin-bottom: 1.8rem;
        font-size: 2rem;
        line-height: 1.2;
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .genre-location {
        margin-bottom: 0.9rem;
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: #848484;

        span {
          color: #d8d8d8;
          padding: 0 0.5rem;
        }
      }

      .tag {
        display: flex;
        flex-wrap: wrap;
        gap: 6px 0;
        overflow: hidden;
        margin-bottom: 0.9rem;
        max-height: 59.18px;

        li {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: .3rem 1rem;
          color: #666;
          font-size: 1.3rem;
          line-height: 1.86rem;
          margin-right: .5rem;
          background-color: #f5f5f5;
          border: 1px solid #ededed;
          border-radius: 2px;
        }
      }

      .review {
        font-size: 1.6rem;
        line-height: 2rem;
        color: #5a5a5a;

        .rating {
          float: left;
          overflow: hidden;
          height: 18px;
          margin-right: 1rem;
        }
      }

      .artistEvent {
        margin: 1rem 0 0.4rem;
        font-size: 1.6rem;
        font-weight: 500;
        color: $color-pink;
      }

      .artist-info-price {
        color: #000;
        font-size: 2.2rem;
        font-weight: 700;
      }
    }
  }
</style>
