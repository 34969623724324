var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "evaluation", attrs: { id: "evaluation" } }, [
    _vm.pointCount && _vm.reviewCount > 0
      ? _c("div", { staticClass: "rating" }, [
          _c("span", { staticClass: "icon icon-star" }),
          _vm._v(" " + _vm._s(_vm.pointCount) + " "),
          _c("span", { staticClass: "review-num" }, [
            _vm._v("(" + _vm._s(_vm.$lib.addComma(_vm.reviewCount)) + ")")
          ])
        ])
      : _vm._e(),
    _vm.favCount > 0
      ? _c("div", { staticClass: "like" }, [
          _c("span", { staticClass: "icon icon-like" }),
          _vm._v(_vm._s(_vm.$lib.addComma(_vm.favCount)) + " ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }