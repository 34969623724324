<template>
  <div id="evaluation" class="evaluation">
      <div class="rating" v-if="pointCount && reviewCount > 0">
          <span class="icon icon-star"></span> {{ pointCount }}
          <span class="review-num">({{ $lib.addComma(reviewCount) }})</span>
      </div>
      <div class="like" v-if="favCount > 0">
          <span class="icon icon-like"></span>{{ $lib.addComma(favCount) }}
      </div>
  </div>
</template>

<script>
  export default {
    name: 'evaluation',
    props : {
      pointCount : {
        type: String,
        default: ''
      },
      reviewCount : {
        type: String,
        default: ''
      },
      favCount : {
        type: String,
        default: ''
      }
    }
}
</script>

<style lang="scss" scoped>
.artist-item {
  .evaluation {
        margin: 1.4rem 0;
        .rating {
          margin-right: 1.5rem;
          font-size: 1.8rem;
        }
      }
}
.evaluation {
  display: flex;
  margin-top: 0.7rem;

  .icon {
      width: 1.3rem;
      height: 1.3rem;
  }
  .rating {
      display: flex;
      align-items: center;
      margin-right: 1.2rem;
      color: #000;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
      .icon-star {
          background: url(/img/rating/star-full.svg) no-repeat 0 0/ 100%;
          margin-top: 2px;
          margin-right: 6px;
      }
      .review-num {
          margin-left: 2px;
          color: #a8a8a8;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 2rem;
      }
  }
  .like {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
      .icon-like {
          background: url(/img/rating/heart.svg) no-repeat 0 0/ 100%;
          margin-top: 2px;
          margin-right: 4px;
      }
  }
}
</style>